import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SigninNumber from "../sections/signinNumber"

function CodeInvite({ location }) {
  return (
    <Layout>
      <SEO title="Cadastro" />
      <SigninNumber deal={location.state?.deal}/>
    </Layout>
  );
}

export default CodeInvite;