import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import querys from '../../service/querys';

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Loading,
  ContainerError,
  ErrorText
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import HeaderProgress from "../../components/HeaderProgress"
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';

const CodeInvite = ({ deal }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleAuthenticate = async (cpf) => {
    await client.request(mutations.RECOVER_PASSWORD_EMAIL, {
      input: {
        cpf,
      },
    });
    navigate('/signin-sms', { state: { deal, cpf }})
  };

  async function onSubmit(data) {
    const { cpf } = data;
    try {
      setLoading(true);

      const { validateLogin } = await client.request(querys.VALIDATE_LOGIN, {
        input: {
          cpf,
        },
      });

      const { isLogin } = validateLogin;

      if (isLogin) {
        navigate('/login', { state: { deal, cpf }});
        return;
      }

      await handleAuthenticate(cpf);
    }catch(errors){
      const code = errors?.response?.errors[0]?.extensions?.code;
      if (code === 'ACCOUNT_NOT_FOUND') {
        try {
          navigate('/serasaAnalytic', { state: { deal, cpf, route: '/signup-participant' }});
        } catch (errors) {
          setError("Ocorreu um erro inesperado")
        }
      }
    } finally{
      setLoading(false)
    }
  }

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>Entre em um grupo:</p></Label>
            </Header>
            <Form>
              <Input
                placeholder="Digite seu CPF"
                type="cel"
                mask="999.999.999-99"
                maskChar=""
                value={formik.values.cpf}
                onChange={(e) => formik.setFieldValue('cpf', e.target.value)}
                error={formik.touched.cpf && formik.errors.cpf}
              />

              {error.length > 0 && (
                <ContainerError>
                  <ErrorText>{error}</ErrorText>
                </ContainerError>
              )}

              {loading && <Loading />}
            </Form>
            <Buttons>
              <Button 
                onClick={formik.submitForm} 
                disabled={
                  formik.values.cpf.length < 14
                } 
              >
                Tenho interesse!
              </Button>
            </Buttons>
          </Body>
        </Section>
      </Container>
    </div>
  )
}

export default CodeInvite
